.grid-container{
   display: grid;
   grid-template-columns: repeat(3, 3fr);
   grid-gap: 1em 2em;
}
.card-content .title{
   font-family: 'Roboto';
   font-weight: bold;
   font-size:15px;
   letter-spacing:0.6px;
}
.card-content{
   margin-top:-30px;
   max-height: 6em;
}

.card{
   width:450px;
}

.row{
   margin-bottom:30px;
}
.card:hover {
   box-shadow: 0 4px 8px 0 #1d1b1b;
 }
@media (max-width: 600px ){
   #mobcard{
      padding-left:80px;
   }
   .case_study_card {
      max-width: 20em;
      margin-left: -15vw;
   }
   .case_study_content {
      min-height: 10em;
   }
}