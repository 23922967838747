.container {
    margin-bottom: 3em;
}

.container .title {
    margin-top: 50px;
    padding-top: 20px;
    font-size: 50px;
}

.message {
    margin-left: 1em;
    margin-right: 1em;
}

.designation {
    padding-top: 20px;
}

.circle-icon,
.officer-img {
    border-radius: 50%;
    margin-top: 20px;
    object-fit: cover;
}

.circle-icon {
    width: 220px;
    height: 220px;
}

.officer-img {
    width: 300px;
    height: 300px;
}

.officer-content {
    width: 100%;
    margin-top: 10px;
    border: 3px dashed rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.ln-icon-block {
    margin-bottom: -55px;
}

.ln-icon {
    position: relative;
    top: -37px;
    height: 55px;
    border: 8px solid #fff;
    border-radius: 50%;
    background: #ffffff;
}

.team-member-block {
    text-align: center;
    margin-bottom: 20px;
}

.team-member-name {
    font-size: 25px;
    margin-bottom: 0px;
}

.team-member-designation {
    margin: 0px;
    font-size: 20px;
}

#web-team-list {
    margin-bottom: 9em;
}


.core-team {
    text-align: left;
    margin-top: 5em;
}

.core-team>h3 {
    display: inline-block;
    position: absolute;
    margin: -0.6em 0 0 2em;
    color: #122B42;
    background-color: white;
    padding: 0 0.1em;
}

.core-team>h5 {
    text-align: center;
    color: rgba(18, 43, 66, 0.81);
    padding: 2em;
    border: 2px dashed #8895a1;
    border-radius: 40px;
    line-height: 32px;
}

.text-center {
    text-align: center;
}

@media(max-width: 1200px) {
    .circle-icon {
        width: 200px;
        height: 200px;
    }

    .team-member-name {
        font-size: 20px;
    }

    .team-member-designation {
        font-size: 17px;
    }
}

@media(max-width: 992px) {
    .offspace {
        top: 75px;
    }

    .officer-content {
        margin-top: 70px;
    }
}

@media(max-width: 500px) {
    .officer-content>h3 {
        font-size: 30px;
    }

    .officer-content>h4 {
        font-size: 20px;
    }

    .core-team>h3 {
        font-size: 2.62rem;
        margin: -0.6em 0 0 1em;
    }
}

@media(max-width: 350px) {
    .officer-img {
        width: 250px;
        height: 250px;
    }
}
