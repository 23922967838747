body {
    margin: 0;
    /*padding: 0*/;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    /*box-sizing: border-box;*/
}

html {
    scroll-behavior: smooth;
}

body {
    /* color:#BF2200;*/ 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.announcements {
    height: auto;
    min-height: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}

.announcement-box {
    background: rgba(18, 43, 66, 0.1);
    padding: 10px;
    margin: 20px auto;
    border-radius: 35px;
    max-width: 90%;
    word-wrap: break-word;
    position: relative;
}

.icon-link {
    color: #bf2200;
    margin: 5px;
}

/*----------------------------------------------------------------------------------------*/

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(199, 198, 198);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bf2200;
    border-radius: 10px;
}

@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

p {
    font-family: "Quicksand", sans-serif;
    font-size: 120%;
}

.nav_img {
    background-color: #ffffffaa;
}
#home {
    margin-top: 1.5em;
    padding-bottom: 2em;
}
.icon-link {
    font-size: 4rem;
    margin-left: 22px;
}
.about-section {
    clear: both;
    background-color: #bf2200;
    border-radius: 170px 170px 0 0;
    margin-top: 60px;
    padding-bottom: 40px;
   /* position: relative;*/
}

#icon_space a {
    margin-right: 1rem;
    margin-top: 1rem;
}

#icons {
    flex: 1;
}

#bottom_margin {
    margin-bottom: 1rem;
}


#top_margin_inverted {
    color: white;
}

#startup_culture {
    background: rgb(223, 72, 59);
}

.projects {
    -webkit-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
    box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
}

#box_curve_bottom {
    background-color: rgb(19, 8, 49);
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#box_curve_top {
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.extra_bottom {
    background-color: #bf2200;
    height: 30px;
}
#contact {
    margin-top: 3em;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
    justify-content: center;
    align-items: center;
}

#footerContact {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding-left: 5rem;
    max-width: 30rem;
}

#ourProductsAndFollowUS{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 35rem;
    padding-right: 5rem;
}

#footerOurProducts {
    flex: 1;
    margin-bottom: 5rem;
}

#footerOurProductsItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.5rem;
}

#lname,#lemail,#lmessage {
    font-size: .9rem;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

#contactForm{
    width: 100%;
}

#submitBtn{
    margin-top: 1rem;
    width: 100%;
    background-color: #bf2200;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
#submitBtn:hover {
    background-color: #981e02;
}
.loadingIcon {
    display: none;
}

.loadingBtn.loading .loadingIcon {
    display: inline-block;
}
textarea {
    width: 25rem;
    height: 10rem;
    color: white;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
form ::placeholder{
    color: white;
    font-family: "Poppins", sans-serif;
}
#icon_space{
    margin-bottom: 9rem;
}
form input[type="text"]{
    font-family: "Poppins", sans-serif;
}
form input[type="text"]:focus, form textarea:focus {
    border-color: #bf2200;
}
form input[type="submit"]:hover {
    background-color: rgb(223, 72, 59);
}
form input[type="submit"]:active {
    width: 6.5em;
}
#CopyrightText {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin: 0;
    color: white;
}

/* #follow-us {
    margin-top: 5em;
} */
.center-alingnment {
    width: 80%;
}

.modal-container {
    padding-bottom: 20px;
}
.modal-container li {
    text-align: left;
}
.modal-content {
    border: 2px solid #bf2200;
    margin: 20px;
    width: auto !important;
    border-radius: 15px;
}
#footerOurProductsItems a h6{
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    body {
        overflow-x: hidden;
    }
    h3 {
        font-size: 39px !important;
    }
    #home {
        margin-top: 0;
    }
    .iedc_img {
        margin: 0 auto;
        width: 15em;
        height: auto;
    }
    #icon_space {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
    .icon-link {
        font-size: 2.5rem;
    }
    .illustration {
        object-fit: cover;
        margin-top: -6em;
    }
    #home > h2 {
        font-size: 39px;
    }
    .officer-content > h3 {
        text-align: center;
        font-size: 25px !important;
    }
    .officer-content > h4 {
        text-align: center;
        font-size: 19px !important;
    }
    .officer-img {
        height: 250px !important;
        width: 250px !important;
    }
    .mobile-text > p {
        font-size: 15px;
    }
    #box_curve_bottom {
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
    }
    #box_curve_top {
        border-top-left-radius: 10%;
        border-top-right-radius: 15%;
        /* padding-top: 40px; */
    }
    #centre_mobile {
        text-align: left;
        
    }
    .mobile_space {
        margin-bottom: 0px;
    }
    .margin_top_mobile {
        margin-top: 0em;
    }

    #contact {
        margin-top: 0;
        padding-top: 2em;
        box-shadow: none;
        flex-direction: column;
    }
    #icons, #footerContact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
    #contactForm {
        max-width: 20rem;
    }
    textarea{
        width: 100%;
        max-width: none;
    }
    #footerContact{
        padding-left: 0;
    }
    #ourProductsAndFollowUS{
        padding-right: 0;
    }
    #footerOurProductsItems {
        padding-left: 2.3rem;
    }
    #footerOurProducts{
        margin-bottom: 2rem;
    }
    #icons{
        margin-bottom: 2rem;
    }
    .about-section {
        border-radius: 50px 50px 0 0;
        padding-bottom: 10px;
    }
    #CopyrightText {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        margin: 0;
        color: white;
    }
}
