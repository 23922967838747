.catalyst-card {
    display: flex;
    flex-direction: row;
    background-color: #C4C4C4;
    max-width: 700px;
    margin: 2rem auto 0 auto;
   font-family: 'Roboto';
}

.catalyst-image {
    width: 250px;
    height: 300px;
    margin: 2rem;
}

.catalyst-content {
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.catalyst-heading1 {
    color: black;
    font-size: 3rem;
}

.catalyst-buttons {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.catalyst-button {
    background-color: #242424; /* Green */
    border: none;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
}

.catalyst-button:hover {
    cursor: pointer;
}

@media (max-width: 600px) {
    .catalyst-card {
        max-width: 400px;
        margin: 1rem;
    }
    .catalyst-heading1 {
        font-size: 2rem;
    }
    .catalyst-image {
        margin: 1rem;
        max-width: 100px;
        max-height: 150px;
    }
    .catalyst-content {
        padding: 1rem;
    }
    .catalyst-button {
        width: 7rem;
        font-size: 12px;
    }
}