.leaderboard-container {
    display: flex;
    justify-content: center;
}
.leaderboard {
    
    margin: 4%;
    width:50%;
    margin-top: 10px;
    background-color: #bf2200;
    color: #eee;
    border-radius: 10px;
    top: 20%;
    box-shadow: 2px 5px 15px #122b42;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    font-family: "Ubuntu", "Microsoft JhengHei", Arial, sans-serif;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.leaderboard h2 {
    border-radius: 10px 10px 0 0;
    background-color: #122b42;
    padding: 15px 0;
    margin: 0;
    text-align: center;
    font-size: 20px;
}
.leaderboard-heading{
    font-size: 28px;
    padding: 8px;
}
.leaderboard-list{
    display: flex;
    font-size: 18px;
    text-transform: capitalize;
}
.trophy{
	margin-right:14px;
	margin-bottom: -5px;
	
  }
.leaderboard-avatar{
    height: 42px;
    width: 42px;
    margin-left: 15px;
    border-radius: 44px;
}
.leaderboard .rank{
    margin-top: 10px;
	position: absolute;
    left: 30px; 

}
.leaderboard .name{
    margin-top: 10px;
    padding-left: 30px;
    text-transform: capitalize;
}
.leaderboard ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.leaderboard ol li {
    background-color: #9f0000;
    padding: 15px 50px;
    position: relative;
    transition-duration: 0.3s;
}

.leaderboard ol li::before {
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
    color: #bf2200;
    text-align: center;
    position: absolute;
    left: 15px;
}

.leaderboard ol li .score {
    position: absolute;
    right: 30px;
    top:26px;
}
.leaderboard ol li:hover {
    transform: scale(1.2);
}
.leaderboard ol:hover li:not(:hover) {
    opacity: 0.5;
}

@media (min-width: 768px) {
    .leaderboard {
        max-width: 70%;
    }
}
@media (max-width: 370px) {
    .leaderboard .score{
        right:25px;
    }
}
@media (max-width: 1000px) {
    .leaderboard{
        flex: 1;
        margin: 25px;
       

    }
    .leaderboard h2{
        padding: 20px;
    }

  
}
