@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
    margin: 0;
    /*padding: 0*/;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    /*box-sizing: border-box;*/
}

html {
    scroll-behavior: smooth;
}

body {
    /* color:#BF2200;*/ 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.announcements {
    height: auto;
    min-height: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}

.announcement-box {
    background: rgba(18, 43, 66, 0.1);
    padding: 10px;
    margin: 20px auto;
    border-radius: 35px;
    max-width: 90%;
    word-wrap: break-word;
    position: relative;
}

.icon-link {
    color: #bf2200;
    margin: 5px;
}

/*----------------------------------------------------------------------------------------*/

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(199, 198, 198);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bf2200;
    border-radius: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

p {
    font-family: "Quicksand", sans-serif;
    font-size: 120%;
}

.nav_img {
    background-color: #ffffffaa;
}
#home {
    margin-top: 1.5em;
    padding-bottom: 2em;
}
.icon-link {
    font-size: 4rem;
    margin-left: 22px;
}
.about-section {
    clear: both;
    background-color: #bf2200;
    border-radius: 170px 170px 0 0;
    margin-top: 60px;
    padding-bottom: 40px;
   /* position: relative;*/
}

#icon_space a {
    margin-right: 1rem;
    margin-top: 1rem;
}

#icons {
    flex: 1 1;
}

#bottom_margin {
    margin-bottom: 1rem;
}


#top_margin_inverted {
    color: white;
}

#startup_culture {
    background: rgb(223, 72, 59);
}

.projects {
    box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
}

#box_curve_bottom {
    background-color: rgb(19, 8, 49);
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#box_curve_top {
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.extra_bottom {
    background-color: #bf2200;
    height: 30px;
}
#contact {
    margin-top: 3em;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
    justify-content: center;
    align-items: center;
}

#footerContact {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding-left: 5rem;
    max-width: 30rem;
}

#ourProductsAndFollowUS{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 35rem;
    padding-right: 5rem;
}

#footerOurProducts {
    flex: 1 1;
    margin-bottom: 5rem;
}

#footerOurProductsItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.5rem;
}

#lname,#lemail,#lmessage {
    font-size: .9rem;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

#contactForm{
    width: 100%;
}

#submitBtn{
    margin-top: 1rem;
    width: 100%;
    background-color: #bf2200;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
#submitBtn:hover {
    background-color: #981e02;
}
.loadingIcon {
    display: none;
}

.loadingBtn.loading .loadingIcon {
    display: inline-block;
}
textarea {
    width: 25rem;
    height: 10rem;
    color: white;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
form ::placeholder{
    color: white;
    font-family: "Poppins", sans-serif;
}
#icon_space{
    margin-bottom: 9rem;
}
form input[type="text"]{
    font-family: "Poppins", sans-serif;
}
form input[type="text"]:focus, form textarea:focus {
    border-color: #bf2200;
}
form input[type="submit"]:hover {
    background-color: rgb(223, 72, 59);
}
form input[type="submit"]:active {
    width: 6.5em;
}
#CopyrightText {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin: 0;
    color: white;
}

/* #follow-us {
    margin-top: 5em;
} */
.center-alingnment {
    width: 80%;
}

.modal-container {
    padding-bottom: 20px;
}
.modal-container li {
    text-align: left;
}
.modal-content {
    border: 2px solid #bf2200;
    margin: 20px;
    width: auto !important;
    border-radius: 15px;
}
#footerOurProductsItems a h6{
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    body {
        overflow-x: hidden;
    }
    h3 {
        font-size: 39px !important;
    }
    #home {
        margin-top: 0;
    }
    .iedc_img {
        margin: 0 auto;
        width: 15em;
        height: auto;
    }
    #icon_space {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
    .icon-link {
        font-size: 2.5rem;
    }
    .illustration {
        object-fit: cover;
        margin-top: -6em;
    }
    #home > h2 {
        font-size: 39px;
    }
    .officer-content > h3 {
        text-align: center;
        font-size: 25px !important;
    }
    .officer-content > h4 {
        text-align: center;
        font-size: 19px !important;
    }
    .officer-img {
        height: 250px !important;
        width: 250px !important;
    }
    .mobile-text > p {
        font-size: 15px;
    }
    #box_curve_bottom {
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
    }
    #box_curve_top {
        border-top-left-radius: 10%;
        border-top-right-radius: 15%;
        /* padding-top: 40px; */
    }
    #centre_mobile {
        text-align: left;
        
    }
    .mobile_space {
        margin-bottom: 0px;
    }
    .margin_top_mobile {
        margin-top: 0em;
    }

    #contact {
        margin-top: 0;
        padding-top: 2em;
        box-shadow: none;
        flex-direction: column;
    }
    #icons, #footerContact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
    #contactForm {
        max-width: 20rem;
    }
    textarea{
        width: 100%;
        max-width: none;
    }
    #footerContact{
        padding-left: 0;
    }
    #ourProductsAndFollowUS{
        padding-right: 0;
    }
    #footerOurProductsItems {
        padding-left: 2.3rem;
    }
    #footerOurProducts{
        margin-bottom: 2rem;
    }
    #icons{
        margin-bottom: 2rem;
    }
    .about-section {
        border-radius: 50px 50px 0 0;
        padding-bottom: 10px;
    }
    #CopyrightText {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        margin: 0;
        color: white;
    }
}

#announcement_dropdown_icon {
    background: none;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    font-family: Quicksand, sans-serif;
    color: #bf2200;
    transition: transform 0.15s ease-in-out;
}

#announcement_dropdown_icon:hover {
    color: black;
}

.contentAlign{
    width: 50vw;
    margin-left:25vw;
}

.rocketSize{
    width: 25vw;
    pointer-events: none;
}

@media only screen and (max-width: 600px) {
    .contentAlign{
        width: 80vw;
        margin-left:10vw;
    }

    .rocketSize{
        width: 50vw;
    }
}

*{
    font-family: 'Roboto', sans-serif;
}

.eventcard{
  background-color: rgb(255, 255, 255);
  color: rgb(170, 33, 33);
  max-width: 35rem;
  height: 12rem;
  display: flex;
  border-radius: 1rem;
  margin: 2rem;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.11);
}
.details{
    display:grid;
    grid-template-columns: 12rem;
}
.eventcard img{
    background-color: beige;
    min-width: 130px;
    min-height: 130px;
    margin: .5rem;
    border-radius: .5rem;

}

.Event-name{
    font-size: 25px;  
    margin: .5rem 1rem;  
    font-weight: 700;
    grid-column-start: 1;
    grid-column-end: 3;
    color: white;
    color: rgb(170, 33, 33);
}

.Event-date{

    font-size: 15px;  
    margin:.2rem;  
    color: white;
    margin-left: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: rgb(170, 33, 33);
}

.Event-status{
    font-size: 15px;  
    margin:.2rem;  
    color: white;
    margin-left: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: rgb(170, 33, 33);
}

.Event-domain{

    font-size: 15px;  
    margin-top: 0.5rem;
    margin-left: 3rem;
    margin-right: .5rem;
    width: 5rem;
    background-color: antiquewhite; 
    color: rgb(33, 33, 33);
    border-radius: .5rem;
    height:2rem;
    text-align: center;
    padding: .3rem;
    color: rgb(170, 33, 33);
    
}

.eventcard button:hover{
    margin : 0rem 0rem 0rem 1rem;
    background-color: rgb(170, 33, 33);
    border: antiquewhite solid .5px;
    border-radius: .5rem;
    text-align: center;
    font-weight: 600;
    height: 2rem;
    width: 6rem;
    color: white;
}

.eventcard button{
   
    margin : 0rem 0rem 0rem 1rem;
    background-color: white;
    border: rgb(170, 33, 33) solid .5px;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    height: 2rem;
    width: 6rem;
    color: rgb(170, 33, 33);
    
}


.card-container{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.events{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.filter-buttons{
    display: flex;
    margin : 1rem 0rem;
    justify-content: center;
}

.filter-buttons button:hover{
    color: white;
    background-color: rgb(170, 33, 33);
}

.filter-buttons button {
    margin : 0rem 0rem 0rem 1rem;
    color: rgb(170, 33, 33);
    background-color: white;
    border: antiquewhite solid .5px;
    border-radius: 1rem;
    text-align: center;
    font-weight: 600;
    height: 2.5rem;
    width: 6rem;
}

.filter-buttons button:active{
    color: white;
    background-color: rgb(170, 33, 33);
}



.container {
    margin-bottom: 3em;
}

.container .title {
    margin-top: 50px;
    padding-top: 20px;
    font-size: 50px;
}

.message {
    margin-left: 1em;
    margin-right: 1em;
}

.designation {
    padding-top: 20px;
}

.circle-icon,
.officer-img {
    border-radius: 50%;
    margin-top: 20px;
    object-fit: cover;
}

.circle-icon {
    width: 220px;
    height: 220px;
}

.officer-img {
    width: 300px;
    height: 300px;
}

.officer-content {
    width: 100%;
    margin-top: 10px;
    border: 3px dashed rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.ln-icon-block {
    margin-bottom: -55px;
}

.ln-icon {
    position: relative;
    top: -37px;
    height: 55px;
    border: 8px solid #fff;
    border-radius: 50%;
    background: #ffffff;
}

.team-member-block {
    text-align: center;
    margin-bottom: 20px;
}

.team-member-name {
    font-size: 25px;
    margin-bottom: 0px;
}

.team-member-designation {
    margin: 0px;
    font-size: 20px;
}

#web-team-list {
    margin-bottom: 9em;
}


.core-team {
    text-align: left;
    margin-top: 5em;
}

.core-team>h3 {
    display: inline-block;
    position: absolute;
    margin: -0.6em 0 0 2em;
    color: #122B42;
    background-color: white;
    padding: 0 0.1em;
}

.core-team>h5 {
    text-align: center;
    color: rgba(18, 43, 66, 0.81);
    padding: 2em;
    border: 2px dashed #8895a1;
    border-radius: 40px;
    line-height: 32px;
}

.text-center {
    text-align: center;
}

@media(max-width: 1200px) {
    .circle-icon {
        width: 200px;
        height: 200px;
    }

    .team-member-name {
        font-size: 20px;
    }

    .team-member-designation {
        font-size: 17px;
    }
}

@media(max-width: 992px) {
    .offspace {
        top: 75px;
    }

    .officer-content {
        margin-top: 70px;
    }
}

@media(max-width: 500px) {
    .officer-content>h3 {
        font-size: 30px;
    }

    .officer-content>h4 {
        font-size: 20px;
    }

    .core-team>h3 {
        font-size: 2.62rem;
        margin: -0.6em 0 0 1em;
    }
}

@media(max-width: 350px) {
    .officer-img {
        width: 250px;
        height: 250px;
    }
}


  .faq-text {
    margin-top: 20px;
    font-size: large;
  }
  
  @media (max-width: 600px) {

    .faq-text {
      padding: 20px;
      text-align: center;
    }
  }
  /**/
  .accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  
  .accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .accordion__item {
    margin-bottom: 1rem;
  }
  
  .accordion__button {
    background-color: #ddd;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
  }
  
  .accordion__button:hover {
    background-color: #ddd;
  }
  
  .accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }
  
  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg);
  }
  
  [hidden] {
    display: none;
  }
  
  .accordion__panel {
    background-color: #f4f4f4;
    color: #444;
    padding: 20px;
    margin-bottom: 2rem;
    animation: fadein 0.35s ease-in;
  }
  
  /* ---------------- Animation part ------------------ */
  
  @keyframes fadein {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
.grid-container{
   display: grid;
   grid-template-columns: repeat(3, 3fr);
   grid-gap: 1em 2em;
}
.card-content .title{
   font-family: 'Roboto';
   font-weight: bold;
   font-size:15px;
   letter-spacing:0.6px;
}
.card-content{
   margin-top:-30px;
   max-height: 6em;
}

.card{
   width:450px;
}

.row{
   margin-bottom:30px;
}
.card:hover {
   box-shadow: 0 4px 8px 0 #1d1b1b;
 }
@media (max-width: 600px ){
   #mobcard{
      padding-left:80px;
   }
   .case_study_card {
      max-width: 20em;
      margin-left: -15vw;
   }
   .case_study_content {
      min-height: 10em;
   }
}
.projects-main {
   text-align: center;
}

.project-row {
  padding: 0 5.5em;
}

.project-main-title {
   color: #BF2200; 
   margin-top: 1.5em; 
   margin-bottom: 50px;
}

.project-card {
   color: #000;
   max-width: 100%;
   border-radius: 8px;
}
.card-image>img{
   border-radius: 8px 8px 0 0 !important;
}
.project-card-content {
   margin-top:-40px;
   padding: 1em 1em 1.8em 1em;
}


.project-title {
   font-weight: bold;
   font-size:26px;
   letter-spacing:0.6px;
   margin-top: 40px;
   padding-top: 10px;
}
.card-body{
   margin-top: -10px;
   font-size: 15px;
   font-family: "Quicksand",sans-serif;
}

.project-card-col {
   padding: 0 1.5em !important;
}

.project-btn {
   margin-top: 0.7em;
   padding: 0.6em 1.3em;
   background: #fff;
   color: #bf2200;
   border-radius: 10px;
   border: 2px solid;
   cursor: pointer;
}

.project-btn:hover {
   transition: 0.4s;
   background: #bf2200;
   color: #fff;
}

.project-btn:focus {
   background: #bf2200;
   color: #fff;
}

@media (max-width: 700px)
{
   .project-card-col {
      margin: auto;
      padding: 0 0em !important;
   }
   
   .project-row {
      padding: 0 2.0em;
   }
   .project-main-title {
      font-size: 35px;
   }
}

@media screen and (min-width: 993px) {
    #red_blog {
        color: white;
        font-size: 16px;
        background-color: #bf2200;
        outline: none;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 40px;
        width: 80px;
        margin-top: 10px;
    }
}
@media screen and (max-width: 992px) {
    #red_blog {
        display: none;
    }
}
@media screen and (max-width: 992px) {
    #mob_blog {
        position: absolute !important;
        color: white;
        font-size: 16px;
        top: 0.7%;
        right: 2.5%;
        background-color: #bf2200;
        outline: none;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 38px;
        width: 70px;
        z-index: 997;
        margin-top: 3px;
    }
}

@media screen and (min-width: 993px) {
    #mob_blog {
        display: none;
    }
}

.catalyst-card {
    display: flex;
    flex-direction: row;
    background-color: #C4C4C4;
    max-width: 700px;
    margin: 2rem auto 0 auto;
   font-family: 'Roboto';
}

.catalyst-image {
    width: 250px;
    height: 300px;
    margin: 2rem;
}

.catalyst-content {
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.catalyst-heading1 {
    color: black;
    font-size: 3rem;
}

.catalyst-buttons {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.catalyst-button {
    background-color: #242424; /* Green */
    border: none;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
}

.catalyst-button:hover {
    cursor: pointer;
}

@media (max-width: 600px) {
    .catalyst-card {
        max-width: 400px;
        margin: 1rem;
    }
    .catalyst-heading1 {
        font-size: 2rem;
    }
    .catalyst-image {
        margin: 1rem;
        max-width: 100px;
        max-height: 150px;
    }
    .catalyst-content {
        padding: 1rem;
    }
    .catalyst-button {
        width: 7rem;
        font-size: 12px;
    }
}
.leaderboard-container {
    display: flex;
    justify-content: center;
}
.leaderboard {
    
    margin: 4%;
    width:50%;
    margin-top: 10px;
    background-color: #bf2200;
    color: #eee;
    border-radius: 10px;
    top: 20%;
    box-shadow: 2px 5px 15px #122b42;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    font-family: "Ubuntu", "Microsoft JhengHei", Arial, sans-serif;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.leaderboard h2 {
    border-radius: 10px 10px 0 0;
    background-color: #122b42;
    padding: 15px 0;
    margin: 0;
    text-align: center;
    font-size: 20px;
}
.leaderboard-heading{
    font-size: 28px;
    padding: 8px;
}
.leaderboard-list{
    display: flex;
    font-size: 18px;
    text-transform: capitalize;
}
.trophy{
	margin-right:14px;
	margin-bottom: -5px;
	
  }
.leaderboard-avatar{
    height: 42px;
    width: 42px;
    margin-left: 15px;
    border-radius: 44px;
}
.leaderboard .rank{
    margin-top: 10px;
	position: absolute;
    left: 30px; 

}
.leaderboard .name{
    margin-top: 10px;
    padding-left: 30px;
    text-transform: capitalize;
}
.leaderboard ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.leaderboard ol li {
    background-color: #9f0000;
    padding: 15px 50px;
    position: relative;
    transition-duration: 0.3s;
}

.leaderboard ol li::before {
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
    color: #bf2200;
    text-align: center;
    position: absolute;
    left: 15px;
}

.leaderboard ol li .score {
    position: absolute;
    right: 30px;
    top:26px;
}
.leaderboard ol li:hover {
    transform: scale(1.2);
}
.leaderboard ol:hover li:not(:hover) {
    opacity: 0.5;
}

@media (min-width: 768px) {
    .leaderboard {
        max-width: 70%;
    }
}
@media (max-width: 370px) {
    .leaderboard .score{
        right:25px;
    }
}
@media (max-width: 1000px) {
    .leaderboard{
        flex: 1 1;
        margin: 25px;
       

    }
    .leaderboard h2{
        padding: 20px;
    }

  
}

.parent{
    display: flex;
    align-items: center;
    justify-content:center;
    padding-top: 3rem;
    flex-wrap: wrap;
    flex-direction: column;
}
.parent .title{
    padding: 0.7rem 0;
    max-width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
p{
    text-align: center;
    font-size: 22px;
}
h3{
    text-align: center;
}
@media only screen and (max-width: 1000px) and (min-width: 600px) {
    .parent .title{
        min-width: 70vw;
    }
    .parent .title p{
        font-size: 16px;
    }

}
@media only screen and (max-width: 600px) {
    .parent .title{
        max-width: 85vw;
    }
    .parent .title p{
        font-size: 16px;
    }
}




/* COLLAB NOT NEEDED PROPERTIES*/

/* @media only screen and (min-width: 1000px){
    #collab{
        min-height: 75vh;
        min-width: 75vw;
        margin: 3rem 0;
    }
} */
    /* #collab{
        min-height: 50vh;
        min-width: 90vw;
        margin: 3rem 0;           FOR MAX WIDTH 1000px MIN WIDTH 600px
    } */
    /* #collab{
        min-height: 80vh;
        min-width: 90vw;
        margin: 3rem 0;           FOR MAX WIDTH 600px
    } */

