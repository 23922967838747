
  .faq-text {
    margin-top: 20px;
    font-size: large;
  }
  
  @media (max-width: 600px) {

    .faq-text {
      padding: 20px;
      text-align: center;
    }
  }
  /**/
  .accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  
  .accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .accordion__item {
    margin-bottom: 1rem;
  }
  
  .accordion__button {
    background-color: #ddd;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
  }
  
  .accordion__button:hover {
    background-color: #ddd;
  }
  
  .accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }
  
  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg);
  }
  
  [hidden] {
    display: none;
  }
  
  .accordion__panel {
    background-color: #f4f4f4;
    color: #444;
    padding: 20px;
    margin-bottom: 2rem;
    animation: fadein 0.35s ease-in;
  }
  
  /* ---------------- Animation part ------------------ */
  
  @keyframes fadein {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }