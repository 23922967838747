.parent{
    display: flex;
    align-items: center;
    justify-content:center;
    padding-top: 3rem;
    flex-wrap: wrap;
    flex-direction: column;
}
.parent .title{
    padding: 0.7rem 0;
    max-width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
p{
    text-align: center;
    font-size: 22px;
}
h3{
    text-align: center;
}
@media only screen and (max-width: 1000px) and (min-width: 600px) {
    .parent .title{
        min-width: 70vw;
    }
    .parent .title p{
        font-size: 16px;
    }

}
@media only screen and (max-width: 600px) {
    .parent .title{
        max-width: 85vw;
    }
    .parent .title p{
        font-size: 16px;
    }
}




/* COLLAB NOT NEEDED PROPERTIES*/

/* @media only screen and (min-width: 1000px){
    #collab{
        min-height: 75vh;
        min-width: 75vw;
        margin: 3rem 0;
    }
} */
    /* #collab{
        min-height: 50vh;
        min-width: 90vw;
        margin: 3rem 0;           FOR MAX WIDTH 1000px MIN WIDTH 600px
    } */
    /* #collab{
        min-height: 80vh;
        min-width: 90vw;
        margin: 3rem 0;           FOR MAX WIDTH 600px
    } */
